import React from 'react'

const DigitalResoucesKey = () => {
	return (
		<div className="my-4 mb-2 ml-4 block font-semibold md:ml-0 md:flex">
			<div className="mr-5 mb-3 flex items-center text-sm sm:mb-0 sm:text-base">
				<img src="/images/logos/schoolpace-connect.svg" className="mr-2 h-5 sm:h-6" alt="schoolpace connect logo" /> ~ myARC{' '}
			</div>
		</div>
	)
}

export default DigitalResoucesKey
