import { Col, Row } from 'components/grid'
import { H2 } from 'components/typography/Headings'
import { P } from 'components/typography/Paragraph'
import React from 'react'

interface Props {
	title: string | JSX.Element[]
	text?: string | JSX.Element[]
	blue?: boolean
	wide?: boolean
	testId?: string
}

const SectionTitle = ({ title, text, blue, wide, testId }: Props) => {
	return (
		<Row center>
			<Col>
				<div data-cy={testId} className={`mx-auto mb-10 ${wide ? 'max-w-4xl' : 'max-w-3xl'} text-center`}>
					<H2 className={`mb-2 text-balance ${blue && 'text-ab-100'}`}>{title}</H2>
					<P className="text-balance">{text}</P>
				</div>
			</Col>
		</Row>
	)
}

export default SectionTitle
